<template>
  <div>
    <div class="set_up">
      <heads :more="showMore" :msg="title_msg"></heads>
      <div class="gn">
        <van-cell v-if="!isweixinminiprogram" title="手机号" is-link @click="go_phone()" />
        <van-cell title="邮箱" is-link @click="go_emails()" />
        <van-cell title="密码" is-link @click="go_password()" />
        <van-cell v-if="is_link" title="微信" is-link :value="is_bind_weixin == 1 ? wx_nickname : '未绑定'" @click="is_ok && is_dialog()" />
        <van-cell v-else title="微信" :value="is_bind_weixin == 1 ? wx_nickname : '未绑定'" />
      </div>
    </div>
    <van-dialog v-model="showDialog" :show-confirm-button="false" :show-cancel-button="false" class="dialog" title="更换微信账号绑定">
      <div class="content">
        <p>请用新微信号进行授权，重新关联。</p>
        <p v-if="change_bind_able" style="color: #ee2e2e;">每个账号一个月仅可换绑1次。</p>
        <p v-else style="color: #ee2e2e;">本月已到达次数上限。</p>
      </div>
      <div v-if="change_bind_able" class="btns">
        <div class="btn1" @click="showDialog=false">取消</div>
        <div class="btn2" @click="go_wx()">{{ change_bind_able ? '换绑' : '绑定' }}</div>
      </div>
      <div v-else class="btns btns2">
        <div class="btn1" @click="showDialog=false">取消</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { Toast } from 'vant'
import { getLoginUserInfo, getMiniUrlLink } from '@/api/login.js'
import heads from '@/compontens/heads.vue'
import commontop from '@/compontens/menu.vue'
import WechatState from '@/utils/isWechatState.js'

export default {
  components: {
    heads,
    commontop
  },
  data() {
    return {
      title_msg: '账号与安全',
      isshow: false,
      showMore: true,
      showDialog: false,
      is_link: false,
      is_bind_weixin: '', // 是否已绑定微信，0否1是
      wx_nickname: '', // is_bind_weixin=1时返回该字段
      change_bind_able: '', // 当月是否可换绑，0否1是
      wx_url: '',
      is_ok: false,
      agency_id: '',
      zkb_pro: '',
      isZkb: false,
      isweixinminiprogram: false
    }
  },
  created() {
    if (WechatState.isWechatMiniprogram) {
      this.isweixinminiprogram = true
    }
    this.zkb_pro = this.$route.query.zkb_pro || ''
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.showMore = false
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    console.log(this.agency_id + this.isZkb + '-----------------')

    this.getLoginUserInfo()
  },
  mounted() {
    this.isWxApplet()
  },
  methods: {
    // 判断是否在微信小程序中
    isWxApplet() {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 必须在WeixinJSBridge回调后进行判断，否则小程序环境检测会不准确
        if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
          this.is_link = false
          // document.addEventListener('WeixinJSBridgeReady', webchatReady, false)
        } else {
          this.ready()
        }
      } else {
        console.log('不在微信浏览器内-手机号/邮箱/密码/微信名页面')
        this.is_link = true
      }
    },
    ready() {
      if (window.__wxjs_environment === 'miniprogram') {
        console.log('在小程序内-手机号/邮箱/密码/微信名页面')
        this.is_link = false
      } else {
        console.log('不在小程序内-手机号/邮箱/密码/微信名页面')
        this.is_link = true
      }
    },
    // 获取绑定的微信
    getLoginUserInfo() {
      getLoginUserInfo().then(res => {
        if (res.errNo === 0) {
          this.is_bind_weixin = res.result.is_bind_weixin
          this.wx_nickname = res.result.wx_nickname
          this.change_bind_able = res.result.change_bind_able
          this.getMiniUrlLink()
        } else {
          this.getMiniUrlLink()
        }
      })
    },
    is_dialog() {
      if (this.is_bind_weixin == 1) {
        this.showDialog = true
      } else {
        if (this.wx_url) {
          window.location.href = this.wx_url
        } else {
          Toast({
            message: '跳转微信失败\n请使用其他方式登录',
            icon: 'close',
            className: 'noticeWidth'
          })
        }
      }
    },
    clicks() {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    go_phone() {
      this.$router.push({ path: '/set_up/account_security/phone' })
    },
    go_emails() {
      this.$router.push({ path: '/set_up/account_security/emails' })
    },
    go_password() {
      this.$router.push({ path: '/changePassword' })
    },
    // 获取小程序跳转链接
    getMiniUrlLink() {
      var info = {}
      if (this.wx_nickname) {
        info = {
          path: '/pages/login/login',
          query: `uuid=${Cookies.get('uuid')}&access_token=${Cookies.get('access_token')}&fromType=3`,
          env_version: 'release'
        }
      } else {
        info = {
          path: '/pages/login/login',
          query: `uuid=${Cookies.get('uuid')}&access_token=${Cookies.get('access_token')}&fromType=2`,
          env_version: 'release'
        }
      }
      getMiniUrlLink(info).then(res => {
        console.log(res, 'res')
        if (res.result) {
          this.wx_url = res.result
          this.is_ok = true
        } else {
          this.is_ok = true
        }
      }).catch(() => {
        this.is_ok = true
      })
    },
    // 微信换绑
    go_wx() {
      console.log(this.wx_url, 'this.wx_url')
      if (this.wx_url) {
        window.location.href = this.wx_url
      } else {
        Toast({
          message: '跳转微信失败\n请使用其他方式登录',
          icon: 'close',
          className: 'noticeWidth'
        })
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.gn {
  margin-top: 30px;
}
van-cell span {
  color: #454545;
}
.dialog{
  font-size: 28px;
  /deep/.van-dialog__header{
    font-weight: 600;
    font-size: 36px;
  }
  .content{
    margin: 30px 0;
    p{
      margin: 20px auto;
      text-align: center;
    }
  }
  .btns{
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 96px;
    font-weight: 600;
    div{
      width: 50%;
    }
    .btn1{
      color: #999;
    }
    .btn2{
      color: #ee2e2e;
    }
  }
  .btns2{
    justify-content: center;
    .btn1{
      width: 100%;
    }
  }
}
</style>
<style>
.noticeWidth{
  width: 260px !important;
  height: 244px !important;
}
.noticeWidth .van-toast__icon{
  margin: 0 0 10px;
}
</style>
